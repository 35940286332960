import React from 'react';
import './Home.css';
import RecommendedStories from './components/RecommendedStories';

const HomePage = () => {
  const data = [
    {
      title: "Tales from Tellus",
      link: "https://google.com",
      description: "Set in a medieval fantasy world, Tales from Tellus tells the story of great heroes and evil enemies.",
      author: "Billy Buttermore",
      numOfUpvotes: 25,
      numOfComments: 4
    },
    {
      title: "The Day She Left",
      link: "https://google.com",
      description: "Set in a medieval fantasy world, Tales from Tellus tells the story of great heroes and evil enemies.",
      author: "Billy Buttermore",
      numOfUpvotes: 16,
      numOfComments: 2
    },
    {
      title: "A Normal Day in the Life",
      link: "https://google.com",
      description: "Set in a medieval fantasy world, Tales from Tellus tells the story of great heroes and evil enemies.",
      author: "Billy Buttermore",
      numOfUpvotes: 48,
      numOfComments: 6
    },
    {
      title: "A Peculiar Life of a Pickle",
      link: "https://google.com",
      description: "Set in a medieval fantasy world, Tales from Tellus tells the story of great heroes and evil enemies. Lorem ipsum blah blah djfks jkds fk fdk fdkiw mfds ajkf dfjks ",
      author: "Billy Buttermore",
      numOfUpvotes: 10,
      numOfComments: 0
    },
  ]

  console.log(data)

  return (
    <div className="home-main">
      <RecommendedStories recStories={data} />
    </div>
  );
};

export default HomePage;
