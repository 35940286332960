import React from 'react';
import './RecommendedStory.css';
import { FaRegCommentAlt } from "react-icons/fa";
import { BiLike } from "react-icons/bi";

const RecommendedStory = ({ story }) => {
  return (
    <a href={story.link} target="_blank" rel="noreferrer" className="story-link">
      <div className="story-card">
        <div className="story-title">{story.title}</div>
        <div className="story-author">by: {story.author}</div>
        <div className="line"></div>
        <div className="story-description">
          {story.description}
        </div>
        <div className="line"></div>
        <div className="story-metrics-container">
          <div className="comments">
            <FaRegCommentAlt className="comment-icon" />
            <div className="comment-count">{story.numOfComments}</div>
          </div>
          <div className="upvotes">
            <BiLike />
            <div className="upvotes-count">{story.numOfUpvotes}</div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default RecommendedStory