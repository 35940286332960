import React, { useState } from 'react';
import './PlanCard.css';

const PlanCard = ({ title, priceMonthly, priceYearly, storyCount, commentCount, upvoteCount }) => {
  return (
    <div className="subscription-box">
      <h2>{title}</h2>
      <p>${priceMonthly}/month OR ${priceYearly}/year</p>
      <div className="features-header">
        Features
      </div>
      <hr />
      <div className="features-list">
        <ul className="feature-unordered-list">
          <li className="feature">
            {storyCount} story posts per month
          </li>
          <li className="feature">
            {commentCount} comments per month
          </li>
          <li className="feature">
            {upvoteCount} upvotes per month
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PlanCard;
