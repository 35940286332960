import React from 'react';
import './Main.css';
import ContestContainer from './components/ContestContainer';
import Footer from '../../components/footer/Footer';

const Main = () => {
  return (
    <div className="main">
      <div className="welcome-container">
        <div className="welcome-header">
          Welcome to Writers Wharf.
        </div>
        <div className='welcome-information'>
          A place where writers can post their short stories for the world to read.
        </div>
      </div>
      <div className="information-container">
        <div className="information-header">
          Your story...your way.
        </div>
        <div className="information-description">
            Upload your short story, a chapter, maybe even a page...it's up to you! Writers Wharf is here to allow your creativity to be seen by other writers, and to hopefully help you out with your writing!
          </div>
      </div>
      <ContestContainer />
      <Footer />
    </div>
  )
}

export default Main