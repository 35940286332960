import React from 'react';
import './RecommendedStories.css';
import RecommendedStory from './RecommendedStory.jsx';

const RecommendedStories = ({ recStories }) => {
  return (
    <div className="rs-container">
      <div className="rs-header">
        Popular Stories
      </div>
      <div className="rs-list">
        {recStories && recStories.map((item, index) => (
          // Assuming you have a component named YourComponent that takes 'item' as a prop
          <RecommendedStory key={index} story={item} />
        ))}
      </div>
    </div>
  )
}

export default RecommendedStories