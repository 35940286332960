import React, { useState } from 'react';
import PlanCard from './PlanCard';
import './Pricing.css';

const Pricing = () => {

  return (
    <div className="pricing-page">
      <h1>Pricing Options</h1>

      <div className="subscription-boxes">
        <PlanCard
          title="Free"
          priceMonthly={0}
          priceYearly={0}
          storyCount={1}
          commentCount={3}
          upvoteCount={10}
        />

        <PlanCard
          title="Standard"
          priceMonthly={1.99}
          priceYearly={19.99}
          storyCount={3}
          commentCount={10}
          upvoteCount={20}
        />

        <PlanCard
          title="Premium"
          priceMonthly={4.99}
          priceYearly={49.99}
          storyCount="Unlimited"
          commentCount="Unlimited"
          upvoteCount="Unlimited"
        />
      </div>
    </div>
  );
};

export default Pricing;
