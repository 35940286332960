import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom if you're using React Router
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p>&copy; 2023 Writers Wharf</p>
        <nav>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/terms-of-service">Terms</Link></li>
            <li><Link to="/privacy-policy">Privacy</Link></li>
            <li><Link to="/accessibility">Accessibility</Link></li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
