import React, { useState } from 'react';
import './WriteStory.css';
import Footer from '../../components/footer/Footer';

const WriteStory = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [storyText, setStoryText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const maxWordCount = 3000;

  const handleTextareaChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.split(/\s+/);
    setWordCount(words.length);
    setStoryText(inputText);

    // Check if the word count exceeds the limit
    if (words.length > maxWordCount) {
      // Truncate the text to the allowed word count
      setStoryText(words.slice(0, maxWordCount).join(' '));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e);
  }
  return (
    <>
      <div className="main-write-story">
        <div className="write-story-header">Submit Your Story</div>
        <div className="write-story-container">
          <form onSubmit={handleSubmit}>
            <label>Title:</label>
            <input type="text" className="story-submission-title" value={title} onChange={(e) => setTitle(e.target.value)} />

            <label>Short Description:</label>
            <input type="text" className="story-submission-description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <div className="word-count">Words Remaining: {3000 - wordCount}</div>
            <textarea
              value={storyText}
              className="story-submission-text"
              onChange={handleTextareaChange}
              onKeyDown={(e) => {
                // Prevent further typing if the word count limit is reached
                if (wordCount >= maxWordCount && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }}
            />
            <button type="submit" className="post-story-btn">Post Story</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default WriteStory