import React, { useState } from 'react'
import './ContestContainer.css';
import LoginPopup from "../../../components/login/LoginPopup";
import SignupPopup from "../../../components/signup/SignupPopup";

const ContestContainer = () => {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);

  const openSignupPopup = () => {
    setSignupOpen(true);
  };

  const closePopup = () => {
    setLoginOpen(false);
    setSignupOpen(false);
  };

  const togglePopup = () => {
    setLoginOpen(!isLoginOpen);
    setSignupOpen(!isSignupOpen);
  };

  return (
    <>
      <div className="contest-container">
        <div className="contest-header">
          Monthly Writing Contest!
        </div>
        <div className="contest-description">
          That's right! Every single month is a writing contest, and the top 3 stories and authors get recognized on the sites main page!
        </div>
        <div className="contest-entry-container">
          <div className="contest-entry-header">
            Want to enter?
          </div>
          <button className="write-story-btn" onClick={openSignupPopup}>Join Writers Wharf</button>
        </div>
      </div>
      <LoginPopup isOpen={isLoginOpen} onClose={closePopup} onToggle={togglePopup} />
      <SignupPopup isOpen={isSignupOpen} onClose={closePopup} onToggle={togglePopup} />
    </>
  )
}

export default ContestContainer